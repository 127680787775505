import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import BlogNews from "../components/blog/pageContents/BlogNews"

const BlogHomePage = ({ data }) => (
  <Layout>
    <SEO title="Tin Tức" />
    <BlogNews data={data.allBlogJson.nodes} />
  </Layout>
)

export default BlogHomePage

export const query = graphql`
  {
    allBlogJson(sort: { fields: order, order: DESC }) {
      nodes {
        author
        tags
        content
        date
        id
        order
        news
        summary
        title
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
      }
    }
  }
`
